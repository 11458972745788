import {CardTitle} from "reactstrap";
import {ChartBase} from "./ChartBase";
import {useData} from "../provider/DataProvider";
import {useAuth} from "../provider/AuthProvider";
import {useStorageState} from 'components/utils/LocalStorage';
import {isNumeric} from "../../utils/utils";

export const humidityChartTheme = (canvas, data) => {
    let backgroundColor = canvas.getContext("2d").createLinearGradient(0, 230, 0, 50);
    backgroundColor.addColorStop(1, "rgba(29,140,248,0.15)");
    backgroundColor.addColorStop(0.4, "rgba(29,140,248,0.02)");
    return {
        label: '습도',
        fill: true,
        backgroundColor,

        borderWidth: 2,
        borderDash: [],
        borderColor: '#1f8ef1',
        borderDashOffset: 0.0,

        pointBorderWidth: 20,
        pointBorderColor: "rgba(255,255,255,0)",

        pointRadius: 5,
        pointBackgroundColor: '#1f8ef1',
        
        pointHoverRadius: 5,
        pointHoverBorderWidth: 18,
        pointHoverBackgroundColor: "white",
        data: data?.map(v => Math.floor(v.humidity * 10) / 10) || [],
    };
}

export const HumidityChart = () => {
    const {state} = useData();
    const [index, setIndex] = useStorageState('humidityChart', 0);
    
    let humidity = '';
    if(isNumeric(state.humidity)){
        humidity = state.humidity + '%';
    }
    const history = state.sensorHistory || [];
    return <ChartBase
        title={<>
            <h5 className="card-category">습도</h5>
            <CardTitle tag="h2">
                <i className="tim-icons icon-bell-55 text-info"/>
                {humidity}
            </CardTitle>
        </>}
        lineData={(canvas) => ({
            labels: history[index]?.map(v => v.time) || [],
            datasets: [humidityChartTheme(canvas, history[index])],
        })}
        buttonList={['시간', '주간', '일간']}
        index={index}
        setIndex={setIndex}/>
}