import {useState} from "react";
import {Button, Row, Col, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,  Modal, ModalBody, Input} from "reactstrap";

import '../../assets/css/remote-con.css';
import {useAuth} from "../provider/AuthProvider";
import {useData} from '../provider/DataProvider';
import {useStorageState} from "../utils/LocalStorage";
import AirConditionerIcon from "../../assets/icon/ac.png";
import {ACTimerModal} from "../modal/ACTimerModal";
import {ACReservationModal} from "../modal/ACReservationModal";

export const AirConditioner = props => {
    const {state} = useData();
    const {jwtFetch} = useAuth();

    const [timerModal, setTimerModal] = useState(false);
    const [reservationModal, setReservationModal] = useState(false);

    const [protocol, setProtocol] = useStorageState('aircon_protocol', 15);
    const [mode, setMode] = useStorageState('aircon_mode', 1);
    const [speed, setSpeed] = useStorageState('aircon_speed', 4);
    const [temperature, setTemperature] = useStorageState('aircon_temperature', 26);

    const setPowerAC = (power) => {
        const json = {power};
        if(power){
            json.protocol = protocol;
            json.mode = mode;
            json.speed = speed;
            json.temperature = temperature;
        }
        jwtFetch('/api/remote', {
            method: 'POST',
            body: JSON.stringify(json),
            headers: {"Content-Type": "application/json"},
        });
    };

    const sensorValues = []
    if(state.temperature != null){
        sensorValues.push(state.temperature + '°C');
    }
    if(state.humidity != null){
        sensorValues.push(state.humidity + '%');
    }
    return <>
        <ACTimerModal visibility={timerModal} setVisibility={setTimerModal}/>
        <ACReservationModal visibility={reservationModal} setVisibility={setReservationModal}/>
        <div style={{padding: '4px 1px'}}>
            <Row className="remote-con" style={{margin: 0}}>
                <Col className='mt-1' xs='2' style={{display: 'flex', padding: 0, justifyContent: 'center'}}>
                    <img src={AirConditionerIcon} className="toggle-icon" alt="Toggle Icon"/>
                </Col>
                <Col className='mt-1' xs='4' style={{padding: 0}}>
                    <span className='remote-con-name'>에어컨</span>
                </Col>
                <Col className='mt-1' xs='6' style={{display: 'flex', padding: 0}}>
                    <Button
                        size="sm"
                        color="info"
                        className="animation-on-hover"
                        onClick={() => setPowerAC(true)}
                        style={{marginLeft: 'auto'}}
                    >
                        켜기
                    </Button>
                    <Button
                        size="sm"
                        className="animation-on-hover ml-1 mr-2"
                        onClick={() => setPowerAC(false)}
                    >
                        끄기
                    </Button>
                </Col>
                <Col xs='12' className="mt-2">
                    <span style={{color: 'white', fontSize: '15px'}}>{sensorValues.join(' / ')}</span>
                </Col>
                <Col xs='12' className="ac-temp">
                    {Array.from({length: 7}, (_, index) => {
                        const temp = 24 + index;
                        return (
                            <span
                                key={index}
                                className={temp === temperature ? ' selected' : ''}
                                onClick={e => setTemperature(parseInt(e.target.innerHTML))}
                            >
                                {temp}°C
                            </span>
                        );
                    })}
                </Col>
                <Col xs='12' className="mt-2">
                    <Row>
                        <Col xs='3' className='pt-2 pr-1 pb-2 pl-2'>
                            <UncontrolledDropdown style={{width: '100%'}}>
                                <DropdownToggle
                                    caret
                                    className="m-0 w-100"
                                    data-toggle="dropdown"
                                >
                                    {['자동', '냉방', '난방', '제습', '송풍'][mode]}
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-black">
                                    {['냉방', '난방', '제습', '송풍'].map((text, index) => 
                                        <DropdownItem
                                            key={index}
                                            className={mode === index + 1 ? 'selected' : ''}
                                            onClick={() => setMode(index + 1)}
                                        >
                                            {text}
                                        </DropdownItem>
                                    )}
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem
                                        className={mode === 0 ? 'selected' : ''}
                                        onClick={() => setMode(0)
                                    }>
                                        자동
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Col>
                        <Col xs='3' className='pt-2 pr-1 pb-2 pl-1'>
                            <UncontrolledDropdown style={{width: '100%'}}>
                                <DropdownToggle
                                    caret
                                    className="m-0 w-100"
                                    data-toggle="dropdown"
                                >
                                    {['강', '중', '약'][4 - speed] || '자동'}
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-black">
                                    {['강', '중', '약'].map((text, index) => 
                                        <DropdownItem
                                            key={index}
                                            className={speed === 4 - index ? 'selected' : ''}
                                            onClick={() => setSpeed(4 - index)}
                                        >
                                            {text}
                                        </DropdownItem>
                                    )}
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem className={speed === 0 ? 'selected' : ''} onClick={() => setSpeed(0)}>자동</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Col>
                        <Col xs='3' className='pt-2 pr-1 pb-2 pl-1'>
                            <Button
                                className="text-truncate m-0 w-100"
                                style={{padding: '10px'}}
                                onClick={() => setTimerModal(!timerModal)}
                            >
                                타이머
                            </Button>
                            {/* <UncontrolledDropdown className="btn-group">
                                <DropdownToggle caret data-toggle="dropdown"></DropdownToggle>
                            </UncontrolledDropdown> */}
                        </Col>
                        <Col xs='3' className='pt-2 pr-2 pb-2 pl-1'>
                            <Button
                                className="text-truncate m-0 w-100"
                                style={{padding: '10px'}}
                                onClick={() => setReservationModal(!reservationModal)}
                            >
                                예약
                            </Button>
                            {/* <UncontrolledDropdown className="btn-group">
                                <DropdownToggle caret data-toggle="dropdown"></DropdownToggle>
                            </UncontrolledDropdown> */}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    </>;
};