// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checker{
    cursor: pointer;
    transition: all .1s ease-in;
}

.checker:hover{
    background-color: rgba(0, 0, 0, 0.28);
}`, "",{"version":3,"sources":["webpack://./src/assets/css/checker.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,2BAA2B;AAC/B;;AAEA;IACI,qCAAqC;AACzC","sourcesContent":[".checker{\r\n    cursor: pointer;\r\n    transition: all .1s ease-in;\r\n}\r\n\r\n.checker:hover{\r\n    background-color: rgba(0, 0, 0, 0.28);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
