import {useState} from 'react';
import {FormGroup, Label, Input, Button} from 'reactstrap';
import {ModalBase} from './ModalBase';
import {CheckerHistoryTable} from '../../components/device/CheckerHistory';

export const CheckerInfoModal = ({visibility, setVisibility, device}) => {
    return (
        <ModalBase title={device.name + " 정보"} visibility={visibility} setVisibility={setVisibility}>
            <CheckerHistoryTable list={[]}/>
            <Button className='animation-on-hover' onClick={() => setVisibility(false)}>닫기</Button>
        </ModalBase>
    );
};

/* const asdf = () =>{
    
    <Modal isOpen={timerModal} toggle={() => showTimerModal(!timerModal)} contentClassName="ac-modal" color="white">
        <div className="modal-header">
            <h2 className="modal-title" style={{color: 'white'}}>에어컨 타이머</h2>
        </div>
        <ModalBody>
            <span style={{color: 'white'}}>시간</span>
            <Input
                className="mt-1 mb-2"
                type="time"
                value={selectedTime}
                onChange={handleTimeChange}
                step="300"/>
            <Button>전달</Button>
            <Button onClick={() => showTimerModal(false)}>닫기</Button>
        </ModalBody>
    </Modal>
} */