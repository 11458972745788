export const isObject = (data) => {
    return !!data && typeof data === 'object';
}

export const isArray = (data) => {
    return isObject(data) && data.constructor === Array;
}

export const isNumeric = (data) => {
    if(typeof data !== 'number'){
        data = parseInt(data);
    }
    return !isNaN(data) && isFinite(data);
}

export const lshift = (num, bit) => {
    return num * Math.pow(2, bit);
}

export const sleep = (millis) => {
    return new Promise(resolve => setTimeout(resolve, millis));
}

export const tryParseJson = (data) => {
    if(!!data && typeof data === 'string'){
        try{
            return JSON.parse(data);
        }catch{}
    }
    return {}
}

export const dateToString = (date, includeTime) => {
    date = typeof date !== 'object' ? new Date(date || 0) : date;
    let output = `${date.getFullYear()}-${(date.getMonth() + 1 + '').padStart(2, '0')}-${(date.getDate() + '').padStart(2, '0')}`;
    if(includeTime){
        output += ` ${(date.getHours() + '').padStart(2, '0')}:${(date.getMinutes() + '').padStart(2, '0')}:${(date.getSeconds() + '').padStart(2, '0')}`;
    }
    return output;
}