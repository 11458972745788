import {Card, CardHeader, CardBody, CardTitle, Row, Col} from "reactstrap";

export const Setting = props => {
    return (
        <div className="content">
            <Row>
                <Col xs='12'>
                    <Card className="card-chart">
                        <CardHeader>
                            <CardTitle tag="h2">준비중인 기능입니다.</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <div className="chart-area">
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}