// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle-icon{
    height: 30px;
    width: 30px;
    filter:invert(1);
}

.switch-bot{
    display: flex;
    align-items: center;

    width: 100%;
    height: 104px;

    border: 2px solid;
    border-radius: 16px;
}
.switch-bot-name{
    color: white;
    font-size: 22px;
    font-weight: bold
}
.switch-bot-button-parent{
    display: flex;
    align-items: center;
}

.switch-bot-button-parent > span{
    color: white;
    font-weight: bold;

    margin-left: auto;
    margin-right: 6px;
}

.switch-bot-button-parent > button{
    margin-right: 6px;
    padding-left: 26px;
    padding-right: 26px;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/switch-bot.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;;IAEnB,WAAW;IACX,aAAa;;IAEb,iBAAiB;IACjB,mBAAmB;AACvB;AACA;IACI,YAAY;IACZ,eAAe;IACf;AACJ;AACA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,iBAAiB;;IAEjB,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":[".toggle-icon{\r\n    height: 30px;\r\n    width: 30px;\r\n    filter:invert(1);\r\n}\r\n\r\n.switch-bot{\r\n    display: flex;\r\n    align-items: center;\r\n\r\n    width: 100%;\r\n    height: 104px;\r\n\r\n    border: 2px solid;\r\n    border-radius: 16px;\r\n}\r\n.switch-bot-name{\r\n    color: white;\r\n    font-size: 22px;\r\n    font-weight: bold\r\n}\r\n.switch-bot-button-parent{\r\n    display: flex;\r\n    align-items: center;\r\n}\r\n\r\n.switch-bot-button-parent > span{\r\n    color: white;\r\n    font-weight: bold;\r\n\r\n    margin-left: auto;\r\n    margin-right: 6px;\r\n}\r\n\r\n.switch-bot-button-parent > button{\r\n    margin-right: 6px;\r\n    padding-left: 26px;\r\n    padding-right: 26px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
