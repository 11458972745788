import {CardTitle} from "reactstrap";
import {ChartBase} from "./ChartBase";
import {useData} from "../provider/DataProvider";
import {useAuth} from "../provider/AuthProvider";
import {isNumeric} from "../../utils/utils";
import {useEffect} from "react";
import {temperatureChartTheme} from "./TemperatureChart";
import {humidityChartTheme} from "./HumidityChart";

export const SensorDataChart = () => {
    const {jwtFetch} = useAuth();
    const {state, dispatch} = useData();

    useEffect(() => {
        if(!state.sensorHistory){
            jwtFetch(`/data/sensor?history=true`, {method: 'POST'})
            .then(async res => {
                const data = await res.json();
                dispatch({
                    key: 'sensorHistory',
                    value: [
                        data.hourSensorHistory,
                        data.dailySensorHistory,
                        data.weeklySensorHistory,
                    ]
                });
            })
            .catch(error => console.error(error));
        }
    }, []);

    let temperature = '';
    if(isNumeric(state.temperature)){
        temperature = state.temperature + '°C';
    }
    let humidity = '';
    if(isNumeric(state.humidity)){
        humidity = state.humidity + '%';
    }
    const history = state.sensorHistory || [];
    return <ChartBase
        title={<>
            <h5 className="card-category">온도 및 습도</h5>
            <CardTitle tag="h2">
                {/* <span className="mr-2">현재</span> */}
                {/* TODO: 올바른 온/습도 아이콘 */}
                <i className="tim-icons icon-delivery-fast text-primary"/>
                <span className="mr-3">{temperature}</span>
                <i className="tim-icons icon-bell-55 text-info"/>
                {humidity}
            </CardTitle>
        </>}
        lineData={(canvas) => ({
            labels: history[0]?.map(v => v.time) || [],
            datasets: [temperatureChartTheme(canvas, history[0]), humidityChartTheme(canvas, history[0])],
        })}/>
}