import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table
} from "reactstrap";
import '../../assets/css/checker.css';
import {useState, useEffect} from "react";
import {dateToString} from '../../utils/utils';
import {useAuth} from '../../components/provider/AuthProvider';
import {useData} from '../../components/provider/DataProvider';
import {CheckerInfoModal} from "../../components/modal/CheckerInfoModal";

const Checker = props => {
    const [modal, setModal] = useState(false);

    const device = props.device;
    if(!device){
        return <></>
    }
    let battery = device.battery;
    if(!battery){
        battery = '전원';
    }else{
        battery += '%';
    }
    const onClick = (event) => {
        setModal(true);
    };
    return <>
        <CheckerInfoModal visibility={modal} setVisibility={setModal} device={device}/>
        <tr className="checker" onClick={onClick}>
            <td className="text-center">{device.name}</td>
            <td className="text-center">{device.open ? '열림' : '닫힘'}</td>
            <td className="text-center">{battery}</td>
            <td className="text-center">{dateToString(device.date, true)}</td>
        </tr>
    </>;
}

export const CheckerList = () => {
    const {jwtFetch} = useAuth();
    const {state, dispatch} = useData();
    
    useEffect(() => {
        if(!state.checkerList){
            jwtFetch(`${window.location.origin}/data/checker`, {method: 'POST'})
            .then(async res => dispatch({key: 'checkerList', value: await res.json()}))
            .catch(error => console.error(error));
        }
    }, []);

    const list = state.checkerList || [];
    return <>
        <Card>
            <CardHeader>
                <CardTitle tag="h2">체커 목록</CardTitle>
            </CardHeader>
            <CardBody>
                <Table className="tablesorter" responsive>
                    <thead className="text-primary">
                        <tr>
                            <th className="text-center">장소</th>
                            <th className="text-center">상태</th>
                            <th className="text-center">배터리</th>
                            <th className="text-center">시각</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((device, index) => <Checker key={index} device={device}/>)}
                    </tbody>
                </Table>
            </CardBody>
        </Card>
    </>;
};