import {useState} from 'react';

export const useStorageState = (key, defaultValue) => {
    const number = typeof defaultValue === 'number';
    const [storageValue, setStorageValue] = useState(() => {
        let data = window.localStorage.getItem(key) || defaultValue;
        if(number){
            data = +data;
        }
        return data;
    });
    const setValue = value => {
        if(number){
            value = +value;
        }
        setStorageValue(value);
        window.localStorage.setItem(key, value);
    };
    return [storageValue, setValue];
}