import {
    Table,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Col,
    FormGroup,
    Label,
    Input,
    UncontrolledTooltip
} from "reactstrap";
import {useState, useEffect} from 'react';
import {useAuth} from "../provider/AuthProvider";
import {useData} from "../provider/DataProvider";
import {AddWakeOnLanModal} from "../modal/AddWakeOnLanModal";

const WakeOnLan = ({data, checked}) => {
    const {jwtFetch} = useAuth();
    const {notifyError} = useData();
    const [editMode, setEditMode] = useState(false);

    const name = data.name
    const address = data.address
    const editId = "edit" + data.id
    
    const wakeUp = () => {
        jwtFetch('/api/wol', {
            method: 'POST',
            body: JSON.stringify({address}),
            headers: {"Content-Type": "application/json"}
        }).catch(notifyError);
    };

    return (
        <tr>
            <td>
                <FormGroup check>
                    <Label check>
                        <Input defaultValue="" type="checkbox" checked={checked}/>
                        <span className="form-check-sign">
                            <span className="check"/>
                        </span>
                    </Label>
                </FormGroup>
            </td>
            {editMode ? <Input></Input> : <td>{name}</td>}
            <td>{address}</td>
            <td>
                <Button
                    size="sm"
                    color="info"
                    className="ml-auto mr-0 animation-on-hover"
                    onClick={wakeUp}
                    style={{transition: '0.3s'}}
                >
                    켜기
                </Button>
            </td>
            <td className="td-actions text-right">
                <Button
                    color="link"
                    id={editId}
                    title=""
                    type="button"
                    onClick={() => setEditMode(!editMode)}
                >
                    <i className="tim-icons icon-pencil wol-edit"/>
                </Button>
                <UncontrolledTooltip target={editId} placement="right">편집</UncontrolledTooltip>
            </td>
        </tr>
    );
}

export const WakeOnLanList = () => {
    const {jwtFetch} = useAuth();
    const {state, dispatch, notifyError} = useData();
    const [modal, setModal] = useState(false);

    const setWolList = value => dispatch({key: 'wolList', value});

    useEffect(() => {
        if(!state.wolList){
            jwtFetch(`${window.location.origin}/data/wol`, {method: 'POST'})
            .then(async res => setWolList(await res.json()))
            .catch(error => console.error(error));
        }
    }, []);

    const addWakeOnLanData = (name, address) => {
        jwtFetch('/api/wol', {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({name, address}),
        }).then(async res => {
            if(res.ok){
                const list = [...state.wolList];
                list.push(await res.json());
                setWolList(list);
            }
        }).catch(notifyError)
        .then(() => setModal(false));
    }
    const removeWakeOnLanData = async () => {
        // TODO: input[check] 구해와서 해당 내용 제거
    }

    return <>
        <AddWakeOnLanModal visibility={modal} setVisibility={setModal} onSubmit={addWakeOnLanData}/>
        <Card className=''>
            <CardHeader style={{display: 'flex'}}>
                <CardTitle tag="h2">LAN으로 깨우기</CardTitle>
                <i
                    id='add-wol'
                    onClick={() => setModal(true)}
                    className='tim-icons icon-simple-add wol-add ml-auto mt-1 mr-1'/>
                <UncontrolledTooltip target='add-wol' placement="top-start">추가</UncontrolledTooltip>
                {/* <UncontrolledDropdown className='ml-auto mt-1'>
                    <DropdownToggle
                        caret
                        color="link"
                        className="btn-icon"
                        data-toggle="dropdown"
                        type="button"
                    >
                        <i className="tim-icons icon-settings-gear-63" />
                    </DropdownToggle>
                    <DropdownMenu aria-labelledby="dropdownMenuLink" right>
                        <DropdownItem onClick={() => showAddWolModal(true)}>추가</DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown> */}
            </CardHeader>
            <CardBody>
                <Table className="wol tablesorter" responsive>
                    <thead className="text-primary">
                        <tr>
                            <th>
                                <FormGroup check>
                                    <Label check>
                                        <Input defaultValue="" type="checkbox" />
                                        <span className="form-check-sign">
                                            <span className="check"/>
                                        </span>
                                    </Label>
                                </FormGroup>
                            </th>
                            <th>이름</th>
                            <th>MAC</th>
                            <th>전원</th>
                            <th>편집</th>
                        </tr>
                    </thead>
                    <tbody>
                        {state.wolList?.map(data => <WakeOnLan key={data.id} data={data}/>) || <tr>
                            <td colSpan='5' style={{paddingTop: '20px'}}>등록된 PC가 없습니다.</td>
                        </tr>}
                    </tbody>
                </Table>
            </CardBody>
        </Card>
    </>;
}