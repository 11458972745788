import '../assets/css/wol.css';
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
} from "reactstrap";
import {useEffect} from 'react';
import NotificationAlert from "react-notification-alert";
import {isObject, tryParseJson} from '../utils/utils';
import {CheckerList} from '../components/device/CheckerList';
import {SwitchBotList} from '../components/device/SwitchBotList';
import {AirConditioner} from "../components/device/AirConditioner";
import {useAuth} from '../components/provider/AuthProvider';
import {useData} from '../components/provider/DataProvider';
import {WakeOnLanList} from '../components/device/WakeOnLan';
import {SensorDataChart} from "../components/chart/SensorDataChart";

export const Main = () => {
    const {socket} = useAuth();
    const {state, dispatch, notiRef} = useData();

    useEffect(() => {
        if(!socket || socket.readyState > WebSocket.OPEN){
            return;
        }
        const listener = (event) => {
            const data = tryParseJson(event.data);
            isObject(data.device) && dispatch(before => {
                const list = before.switchBotList || [];
                const id = list.findIndex(switchBot => switchBot.id === data.device.id);
                if(id > -1){
                    list[id] = {...list[id], ...data.switchBot};
                }
                return {key: 'switchBotList', value: list};
            });
            isObject(data.checker) && dispatch(before => {
                const list = before.checkerList || [];
                const id = list.findIndex(checker => checker.id === data.checker.id);
                if(id < 0){
                    list.push(data.checker);
                }else{
                    list[id] = {...list[id], ...data.checker};
                }
                return {key: 'checkerList', value: list};
            });

            isObject(data.switchBot) && dispatch(before => {
                const list = before.switchBotList || [];
                const id = list.findIndex(switchBot => switchBot.id === data.switchBot.id);
                if(id < 0){
                    list.push(data.switchBot);
                }else{
                    list[id] = {...list[id], ...data.switchBot};
                }
                return {key: 'switchBotList', value: list};
            });
        };
        socket.addEventListener('message', listener);
        /*socket.addEventListener('error', () => {
            if(socket.readyState === EventSource.CLOSED){
                if(errorCount++ % 4 === 0){
                    notiRef.current.notificationAlert({
                        place: 'tr',
                        type: 'danger',
                        icon: "tim-icons icon-bell-55",
                        message: <div>서버와의 연결이 끊어졌습니다.<br/>잠시 후 다시 시도합니다.</div>,
                        autoDismiss: 3,
                    });
                }
                timeout = setTimeout(() => createEventSource(), 1000);
            }
        });*/
        return () => {
            socket?.removeEventListener('message', listener);
        }
    }, [socket]);

    return (
        <div className="content">
            <div className="react-notification-alert-container">
                <NotificationAlert ref={notiRef} />
            </div>
            <Row>
                <Col md="6">
                    <SwitchBotList/>
                </Col>
                <Col md="6">
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h2">리모컨</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <AirConditioner/>
                        </CardBody>
                    </Card>
                </Col>
                <Col md="6">
                    <CheckerList checkerList={state.checkerList || []}/>
                </Col>
                <Col md="6">
                    <WakeOnLanList/>
                </Col>
                <Col md="12">
                    <SensorDataChart/>
                </Col>
            </Row>
        </div>
    );
}