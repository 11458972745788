// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wol th, .wol td{
    padding: 6px !important;
    text-align: center !important;
}

.wol-add{
    color: #9A9A9A;
    cursor: pointer;
    transition: all 0.4s;
    font-size: 1.4em !important;
}
.wol-add:hover{
    color: #FFF;
}

.wol-edit{
    color: #9A9A9A;
    font-size: 1.5em !important;
}
.wol-edit:hover{
    color: #FFF;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/wol.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,6BAA6B;AACjC;;AAEA;IACI,cAAc;IACd,eAAe;IACf,oBAAoB;IACpB,2BAA2B;AAC/B;AACA;IACI,WAAW;AACf;;AAEA;IACI,cAAc;IACd,2BAA2B;AAC/B;AACA;IACI,WAAW;AACf","sourcesContent":[".wol th, .wol td{\r\n    padding: 6px !important;\r\n    text-align: center !important;\r\n}\r\n\r\n.wol-add{\r\n    color: #9A9A9A;\r\n    cursor: pointer;\r\n    transition: all 0.4s;\r\n    font-size: 1.4em !important;\r\n}\r\n.wol-add:hover{\r\n    color: #FFF;\r\n}\r\n\r\n.wol-edit{\r\n    color: #9A9A9A;\r\n    font-size: 1.5em !important;\r\n}\r\n.wol-edit:hover{\r\n    color: #FFF;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
