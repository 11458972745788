import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table
} from "reactstrap";
import {dateToString} from "../../utils/utils";

const SwitchBot = props => {
    const device = props.device;
    return (
        <tr>
            <td className="text-center">{device.number}</td>
            <td className="text-center">{device.name}({device.channel ? '아래' : '위'})</td>
            <td className="text-center">{device.state ? 'ON' : 'OFF'}</td>
            <td className="text-center">{dateToString(device.date, true)}</td>
        </tr>
    );
};

export const SwitchBotHistory = props => {
    return (
        <Card>
            <CardHeader>
                <CardTitle tag="h2">스위치봇 기록</CardTitle>
            </CardHeader>
            <CardBody>
                <Table className="tablesorter" responsive>
                    <thead className="text-primary">
                        <tr>
                            <th className="text-center">No</th>
                            <th className="text-center">장소</th>
                            <th className="text-center">상태</th>
                            <th className="text-center">시각</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.switchBotList.map((item, index) => <SwitchBot key={index} device={item}/>)}
                    </tbody>
                </Table>
            </CardBody>
        </Card>
    );
};
