import "assets/css/nucleo-icons.css";
import "assets/scss/black-dashboard-react.scss";
import "@fortawesome/fontawesome-free/css/all.min.css";

import ReactDOMClient from "react-dom/client";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";

import {Login} from "./view/Login"
import {MainLayout} from "./view/layout/MainLayout";
import {AuthProvider, useAuth} from "./components/provider/AuthProvider";
import {DataProvider} from "./components/provider/DataProvider"

const App = () => {
    const {authentication} = useAuth();
    if(authentication == null){
        return <></>;
    }

    return (
        <BrowserRouter>
            <Routes>
                {authentication ? (
                    <Route path="*" element={<MainLayout/>}/>
                ) : (<>
                    <Route path="/" element={<Login/>}/>
                    <Route path="*" element={<Navigate to="/"/>}/>
                </>)}
            </Routes>
        </BrowserRouter>
    );
}

const root = ReactDOMClient.createRoot(document.getElementById("root"));
root.render(
    <DataProvider>
        <AuthProvider>
            <App/>
        </AuthProvider> 
    </DataProvider> 
);