import {Route, Routes, useLocation} from "react-router-dom";
import {useRef, useState, useEffect, Fragment} from "react";
import {urlBase64ToUint8Array} from '../../notify/utils';

import PerfectScrollbar from "perfect-scrollbar";

import Footer from "../footer/Footer";
import {Header} from "../header/Header";
import {Sidebar} from "../sidebar/Sidebar";

import {Main} from '../../view/Main';
import {Setting} from '../../view/Setting';
import {NotFound} from '../../view/NotFound';
import {Statistics} from '../../view/Statistics';
import {useAuth} from "../../components/provider/AuthProvider";

let ps;
export const MainLayout = () => {
    const {jwtFetch} = useAuth();
    const location = useLocation();
    const mainPanelRef = useRef(null);
    const [sidebarOpened, setSidebarOpened] = useState(
        document.documentElement.className.indexOf("nav-open") !== -1
    );

    useEffect(() => {
        const registerWebPush = async () => {
            try{
                const registration = await navigator.serviceWorker.register('./js/worker.js');
                const subscription = await registration.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: urlBase64ToUint8Array(await (await jwtFetch('/notify/get-key')).text()),
                });
                jwtFetch('/notify/subscribe', {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify(subscription),
                });
            }catch(e){
                console.error(e);
            }
        }
        if(window.Notification && Notification.permission !== 'granted'){
            Notification.requestPermission()
            .then(permission => permission !== 'granted' || registerWebPush())
            .catch(e => alert(e));
        }
    }, []);

    /*useEffect(() => {
        if(navigator.userAgent.indexOf("Win") > -1){
            document.documentElement.classList.add("perfect-scrollbar-on");
            document.documentElement.classList.remove("perfect-scrollbar-off");
            ps = new PerfectScrollbar(mainPanelRef.current, {
                suppressScrollX: true,
            });
            let tables = document.querySelectorAll(".table-responsive");
            for(let i = 0; i < tables.length; i++){
                ps = new PerfectScrollbar(tables[i]);
            }
        }
        return () => {
            if(navigator.userAgent.indexOf("Win") > -1){
                ps.destroy();
                document.documentElement.classList.add("perfect-scrollbar-off");
                document.documentElement.classList.remove("perfect-scrollbar-on");
            }
        };
    }, []);*/
    useEffect(() => {
        if(navigator.userAgent.indexOf("Win") > -1){
            let tables = document.querySelectorAll(".table-responsive");
            for(let i = 0; i < tables.length; i++){
                ps = new PerfectScrollbar(tables[i]);
            }
        }
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        if(mainPanelRef.current){
            mainPanelRef.current.scrollTop = 0;
        }
    }, [location]);

    let routeList = [
        {
            path: "/",
            name: "IoT Webpage",
            sidebar: "기기 조작",
            icon: "tim-icons icon-button-power",
            component: <Main/>,
        },
        {
            path: "/statistics",
            name: "Statistics",
            sidebar: "사용 통계",
            icon: "tim-icons icon-chart-pie-36",
            component: <Statistics/>,
        },
        {
            path: "/setting",
            name: "Setting",
            sidebar: "설정",
            icon: "tim-icons icon-chart-pie-36",
            component: <Setting/>,
        },
    ];

    const toggleSidebar = () => {
        document.documentElement.classList.toggle("nav-open");
        setSidebarOpened(!sidebarOpened);
    };
    return (
        <Fragment>
            <div className="wrapper">
                <Sidebar
                    routes={routeList}
                    toggleSidebar={toggleSidebar}/>
                <div className="main-panel" ref={mainPanelRef} data='blue'>
                    <Header
                        brandText={routeList.find(route => route.path === location.pathname)?.name || "IoT Webpage"}
                        toggleSidebar={toggleSidebar}
                        sidebarOpened={sidebarOpened}/>
                    <Routes>
                        {routeList.map((prop, key) => <Route path={prop.path} element={prop.component} key={key}/>)}
                        <Route path='*' element={<NotFound/>}/>
                    </Routes>
                    <Footer fluid/>
                </div>
            </div>
        </Fragment>
    )
}