import {CardTitle} from "reactstrap";
import {ChartBase} from "./ChartBase";
import {useStorageState} from '../utils/LocalStorage';
import {useData} from "../provider/DataProvider";
import {useAuth} from "../provider/AuthProvider";
import {isNumeric} from "../../utils/utils";
import {useEffect} from "react";

export const temperatureChartTheme = (canvas, data) => {
    let backgroundColor = canvas.getContext("2d").createLinearGradient(0, 230, 0, 50);
    backgroundColor.addColorStop(1, "rgba(208,72,182,0.15)");
    backgroundColor.addColorStop(0.4, "rgba(208,72,182,0.02)");
    return {
        label: '온도',
        fill: true,
        backgroundColor,

        borderWidth: 2,
        borderDash: [],
        borderColor: '#d048b6',
        borderDashOffset: 0.0,

        pointBorderWidth: 20,
        pointBorderColor: "rgba(255,255,255,0)",

        pointRadius: 5,
        pointBackgroundColor: '#d048b6',
        
        pointHoverRadius: 5,
        pointHoverBorderWidth: 18,
        pointHoverBackgroundColor: "white",
        data: data?.map(v => Math.floor(v.temperature * 100) / 100) || [],
    }
}

export const TemperatureChart = () => {
    const {jwtFetch} = useAuth();
    const {state, dispatch} = useData();
    const [index, setIndex] = useStorageState('temperatureIndex', 0);

    useEffect(() => {
        if(!state.sensorHistory){
            jwtFetch(`/data/sensor?history=true`, {method: 'POST'})
            .then(async res => {
                const data = await res.json();
                dispatch({
                    key: 'sensorHistory',
                    value: [
                        data.hourSensorHistory,
                        data.dailySensorHistory,
                        data.weeklySensorHistory,
                    ]
                });
            })
            .catch(error => console.error(error));
        }
    }, []);

    let temperature = '';
    if(isNumeric(state.temperature)){
        temperature = state.temperature + '°C';
    }
    const history = state.sensorHistory || [];
    return <ChartBase
        title={<>
            <h5 className="card-category">온도</h5>
            <CardTitle tag="h2">
                {/* <span className="mr-2">현재</span> */}
                {/* TODO: 올바른 온/습도 아이콘 */}
                <i className="tim-icons icon-delivery-fast text-primary"/>
                {temperature}
            </CardTitle>
        </>}
        lineData={(canvas) => ({
            labels: history[index]?.map(v => v.time) || [],
            datasets: [temperatureChartTheme(canvas, history[index])],
        })}
        buttonList={['시간', '주간', '일간']}
        index={index}
        setIndex={setIndex}/>
}