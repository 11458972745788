import React from "react";

import { Container, Nav, NavItem, NavLink } from "reactstrap";

function Footer() {
  return (
    <footer className="footer">
      <Container fluid>
        <Nav>
          <NavItem>
            <NavLink href="#">
              안녕
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="#">
              하세요
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="#">
              반가워요
            </NavLink>
          </NavItem>
        </Nav>
        <div className="copyright">
          © {new Date().getFullYear()}년에도 항상 함께합시다.
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
