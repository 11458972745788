import {
    Collapse,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    NavbarBrand,
    Navbar,
    NavLink,
    Nav,
    Container,
    NavbarToggler,
} from "reactstrap";
import classNames from "classnames";
import {useState, useEffect} from "react";
import LoginProfile from "../../assets/img/login.png";
import {useAuth} from "../../components/provider/AuthProvider";

export const Header = props => {
    const {authentication, setAuthentication} = useAuth();
    const [collapseOpen, setCollapseOpen] = useState(false);
    const [color, setColor] = useState('navbar-transparent');

    const updateColor = () => {
        setColor(window.innerWidth < 993 && collapseOpen ? 'bg-white' : 'navbar-transparent');
    };

    useEffect(() => {
        window.addEventListener('resize', updateColor);
        return () => window.removeEventListener('resize', updateColor);
    });

    useEffect(() => {
        if(!authentication){
            setCollapseOpen(false);
            setColor('navbar-transparent');
        }
    }, [authentication]);

    const toggleCollapse = () => {
        if(!authentication){
            setCollapseOpen(false);
            return;
        }
        setColor(collapseOpen ? 'navbar-transparent' : 'bg-white');
        setCollapseOpen(!collapseOpen);
    };

    const logout = () => {
        fetch(`/logout`).then(res => res.ok && setAuthentication(false));
    };

    let dropMenu = <><div className="photo"></div></>;
    let profileButton = <></>;
    if(authentication){
        profileButton = <>
            <div className="photo">
                <img alt="..." src={LoginProfile}/>
            </div>
            <b className="caret d-none d-lg-block d-xl-block"/>
            <p className="d-lg-none">유저명</p>
        </>;
        dropMenu = <>
            <DropdownMenu className="dropdown-navbar" right tag="ul">
                {/* <NavLink tag="li">
                    <DropdownItem className="nav-item">Profile</DropdownItem>
                </NavLink>
                <NavLink tag="li">
                    <DropdownItem className="nav-item">Settings</DropdownItem>
                </NavLink>
                <DropdownItem divider tag="li" /> */}
                <NavLink tag="li">
                    <DropdownItem className="nav-item" onClick={logout}>Log out</DropdownItem>
                </NavLink>
            </DropdownMenu>
        </>;
    }
    return (
        <Navbar className={classNames("navbar-absolute", color)} expand="lg">
            <Container fluid>
                <div className="navbar-wrapper">
                    <div className={classNames("navbar-toggle d-inline", {toggled: props.sidebarOpened})}>
                        <NavbarToggler onClick={props.toggleSidebar}>
                            <span className="navbar-toggler-bar bar1"/>
                            <span className="navbar-toggler-bar bar2"/>
                            <span className="navbar-toggler-bar bar3"/>
                        </NavbarToggler>
                    </div>
                    <NavbarBrand style={{fontSize: '32px', fontWeight: 'bold'}} href="#" onClick={(e) => e.preventDefault()}>
                        {props.brandText}
                    </NavbarBrand>
                </div>
                <NavbarToggler onClick={toggleCollapse} style={{cursor: authentication ? "pointer" : "auto"}}> {/* 메뉴 버튼 */}
                    <span className="navbar-toggler-bar navbar-kebab"/>
                    <span className="navbar-toggler-bar navbar-kebab"/>
                    <span className="navbar-toggler-bar navbar-kebab"/>
                </NavbarToggler>
                <Collapse navbar isOpen={collapseOpen}>
                    <Nav className="ml-auto" navbar>
                        <UncontrolledDropdown nav>
                            <DropdownToggle
                                nav
                                caret
                                color="default"
                                style={{cursor: authentication ? "pointer" : "auto"}}
                                onClick={(e) => e.preventDefault()}
                            >{profileButton}</DropdownToggle>
                            {dropMenu}
                        </UncontrolledDropdown>
                        <li className="separator d-lg-none" />
                    </Nav>
                </Collapse>
            </Container>
        </Navbar>
    );
}