import {useState} from 'react';
import {FormGroup, Label, Input, Button} from 'reactstrap';
import {ModalBase} from './ModalBase';

export const ACTimerModal = ({visibility, setVisibility}) => {
    const [selectedTime, setSelectedTime] = useState('00:00');
    const handleTimeChange = (event) => {
        setSelectedTime(event.target.value);
    };
    return (
        <ModalBase title="에어컨 타이머" visibility={visibility} setVisibility={setVisibility}>
            <FormGroup>
                <Input
                    className="mt-1 mb-2"
                    type="time"
                    step="300"
                    value={selectedTime}
                    style={{color: '#000'}}
                    onChange={handleTimeChange}/>
                {/* <Label for="pc">아래의 시간 이후에 종료됩니다.</Label> */}
            </FormGroup>
            <div>후 종료됩니다</div>
            <Button color='info' className='animation-on-hover' onClick={() => alert('아직 준비중인 기능입니다.')}>추가</Button>
            <Button className='animation-on-hover' onClick={() => setVisibility(false)}>취소</Button>
        </ModalBase>
    );
};

/* const asdf = () =>{
    
    <Modal isOpen={timerModal} toggle={() => showTimerModal(!timerModal)} contentClassName="ac-modal" color="white">
        <div className="modal-header">
            <h2 className="modal-title" style={{color: 'white'}}>에어컨 타이머</h2>
        </div>
        <ModalBody>
            <span style={{color: 'white'}}>시간</span>
            <Input
                className="mt-1 mb-2"
                type="time"
                value={selectedTime}
                onChange={handleTimeChange}
                step="300"/>
            <Button>전달</Button>
            <Button onClick={() => showTimerModal(false)}>닫기</Button>
        </ModalBody>
    </Modal>
} */