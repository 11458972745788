import {createContext, useRef, useState, useContext, useEffect} from 'react';
import {isNumeric, tryParseJson} from '../../utils/utils';
import {useData} from './DataProvider';

const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({children}) => {
    const socket = useRef(null);
    const {dispatch} = useData();
    const [dummySocket, setDummySocket] = useState(null);
    const [authentication, setAuthentication] = useState(null);

    const refreshToken = async () => {
        const res = await fetch('/token/refresh', {
            method: 'POST',
            credentials: 'include'
        });
        return res.ok;
    }

    const jwtFetch = async (url, options = {}) => {
        const response = await fetch(url, {
            ...options,
            credentials: 'include',
            headers: options.headers || {}
        });
        if(response.status === 401){
            setAuthentication(false);
        }
        return response;
    };

    useEffect(() => {
        const tryLogin = async () => {
            try{
                let res = await jwtFetch(`/token/verify`, {method: 'POST'});
                setAuthentication(res.ok);
            }catch{}
        }
        if(authentication == null){
            tryLogin();
        }

        let reconnectTimeout;
        const connectWebSocket = () => {
            if(authentication && (socket.current?.readyState || 3) > WebSocket.OPEN){
                let url;
                if(window.location.protocol !== 'https:'){
                    url = `ws://leinne.net:8080/ws`;
                }else if(process.env.NODE_ENV === 'development'){
                    url = `wss://iot.leinne.net/ws`;
                }else{
                    url = `wss://${window.location.host}/ws`;
                }
                socket.current = new WebSocket(url);
                socket.current.addEventListener('open', () => {
                    socket.current.send(JSON.stringify({method: 'JOIN_CLIENT'}));
                })
                socket.current.addEventListener('message', (event) => {
                    const data = tryParseJson(event.data);
                    isNumeric(data.humidity) && dispatch({
                        key: 'humidity',
                        value: isNumeric(data.humidity) && data.humidity > 0 ? data.humidity : null
                    });
                    isNumeric(data.temperature) && dispatch({
                        key: 'temperature',
                        value: isNumeric(data.temperature) && data.temperature > 0 ? data.temperature : null
                    });
                });
                socket.current.addEventListener('close', async event => {
                    if(event.code === 1003){
                        setAuthentication(false);
                    }else if(authentication){
                        reconnectTimeout = setTimeout(connectWebSocket, 1000);
                    }
                });
                setDummySocket(socket.current);
            }
        };
        if(authentication){
            connectWebSocket();
        }else if(socket.current){
            setDummySocket(null);
            socket.current.close();
        }

        return () => {
            if(reconnectTimeout){
                clearTimeout(reconnectTimeout);
            }
            if(socket.current){
                setDummySocket(null);
                socket.current.close();
            }
        };
    }, [authentication]);

    return (
        <AuthContext.Provider value={{authentication, setAuthentication, jwtFetch, socket: dummySocket}}>
            {children}
        </AuthContext.Provider>
    );
};
