import NotificationAlert from "react-notification-alert";
import {createContext, useReducer, useContext, useRef} from 'react';

const DataContext = createContext();

const dataReducer = (state, action) => {
    action = typeof action === 'function' ? action(state) : action;
    return {
        ...state,
        [action.key]: action.value
    };
}

export const DataProvider = ({children}) => {
    const notiRef = useRef(null);
    const [state, dispatch] = useReducer(dataReducer, {});

    const notifyError = (error) => {
        console.log(error);
        if(notiRef.current == null){
            alert('서버 접속에 실패했습니다. 잠시 후 다시 시도합니다');
            return;
        }
        notiRef.current?.notificationAlert({
            place: 'tr',
            message: (
                <div>서버 접속에 실패했습니다.<br/>잠시 후 다시 시도합니다.</div>
            ),
            type: 'danger',
            icon: "tim-icons icon-bell-55",
            autoDismiss: 5,
        });
    };

    return (
        <DataContext.Provider value={{state, dispatch, notifyError}}>
            <div className="react-notification-alert-container">
                <NotificationAlert ref={notiRef}/>
            </div>
            {children}
        </DataContext.Provider>
    );
}

export const useData = () => useContext(DataContext);