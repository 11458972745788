import {useState} from 'react';
import {FormGroup, Label, Input, Button} from 'reactstrap';
import {ModalBase} from './ModalBase';
import classNames from 'classnames'

const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토'];

export const ACReservationModal = ({visibility, setVisibility}) => {
    const [selectedDayList, setSelectedDayList] = useState([]);
    const [time, setTime] = useState('00:00');

    const handleDayClick = day => {
        setSelectedDayList(prev => prev.includes(day) ? prev.filter(d => d !== day) : [...prev, day]);
    };

    return (
        <ModalBase title="에어컨 예약" visibility={visibility} setVisibility={setVisibility}>
            <FormGroup>
                <Label for="pc">시간</Label>
                <Input
                    className="mt-1 mb-2"
                    type="time"
                    step="300"
                    value={time}
                    style={{color: '#000'}}
                    onChange={event => setTime(event.target.value)}/>
            </FormGroup>
            <Label>요일</Label>
            <div className='day-button-container'>
                {daysOfWeek.map((day) => (
                    <button
                        key={day}
                        onClick={() => handleDayClick(day)}
                        className={classNames('day-button', {selected: selectedDayList.includes(day)})}
                    >
                        {day}
                    </button>
                ))}
            </div>
            <Button color='info' className='animation-on-hover' onClick={() => alert('아직 준비중인 기능입니다.')}>추가</Button>
            <Button className='animation-on-hover' onClick={() => setVisibility(false)}>취소</Button>
        </ModalBase>
    );
};

/* const asdf = () =>{
    <Modal isOpen={timerModal} toggle={() => showTimerModal(!timerModal)} contentClassName="ac-modal" color="white">
        <div className="modal-header">
            <h2 className="modal-title" style={{color: 'white'}}>에어컨 타이머</h2>
        </div>
        <ModalBody>
            <span style={{color: 'white'}}>시간</span>
            <Input
                className="mt-1 mb-2"
                type="time"
                value={selectedTime}
                onChange={handleTimeChange}
                step="300"/>
            <Button>전달</Button>
            <Button onClick={() => showTimerModal(false)}>닫기</Button>
        </ModalBody>
    </Modal>
} */