import {Card, CardHeader, CardBody, CardTitle, Row, Col} from "reactstrap";

export const NotFound = () => {
    return (
        <div className="content">
            <Row>
                <Col xs='12'>
                    <Card className="card-chart">
                        <CardHeader>
                            <CardTitle tag="h2">이곳에는 아무것도 없습니다.</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <div className="chart-area">
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}