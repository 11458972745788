import classNames from "classnames";
import {
    Button,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    ButtonGroup,
} from "reactstrap";
import {Line} from "react-chartjs-2";

export const chartOption = {
    maintainAspectRatio: false,
    legend: {
        display: false,
    },
    tooltips: {
        backgroundColor: "#f5f5f5",
        titleFontColor: "#333",
        bodyFontColor: "#666",
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest",
    },
    responsive: true,
    scales: {
        yAxes: {
            barPercentage: 1.6,
            gridLines: {
                drawBorder: false,
                color: "rgba(29,140,248,0.0)",
                zeroLineColor: "transparent",
            },
            ticks: {
                suggestedMin: 60,
                suggestedMax: 125,
                padding: 20,
                fontColor: "#9a9a9a",
            },
        },
        xAxes: {
            barPercentage: 1.6,
            gridLines: {
                drawBorder: false,
                color: "rgba(29,140,248,0.1)",
                zeroLineColor: "transparent",
            },
            ticks: {
                padding: 20,
                fontColor: "#9a9a9a",
            },
        },
    },
};

export const temperatureChart = (data) => (canvas) => {
    let ctx = canvas.getContext("2d");
    
    let backgroundColor = ctx.createLinearGradient(0, 230, 0, 50);
    backgroundColor.addColorStop(1, "rgba(208,72,182,0.15)");
    backgroundColor.addColorStop(0.4, "rgba(208,72,182,0.02)");

    return {
        labels: data?.map(v => v.time) || [],
        datasets: [
            {
                label: '온도',
                fill: true,
                backgroundColor,

                borderWidth: 2,
                borderDash: [],
                borderColor: '#d048b6',
                borderDashOffset: 0.0,

                pointBorderWidth: 20,
                pointBorderColor: "rgba(255,255,255,0)",

                pointRadius: 5,
                pointBackgroundColor: '#d048b6',
                
                pointHoverRadius: 5,
                pointHoverBorderWidth: 18,
                pointHoverBackgroundColor: "white",
                data: data?.map(v => Math.floor(v.temperature * 10) / 10) || [],
            }
        ],
    };
}

export const humidityChart = (data) => (canvas) => {
    let ctx = canvas.getContext("2d");

    let backgroundColor = ctx.createLinearGradient(0, 230, 0, 50);
    backgroundColor.addColorStop(1, "rgba(29,140,248,0.15)");
    backgroundColor.addColorStop(0.4, "rgba(29,140,248,0.02)");

    return {
        labels: data?.map(v => v.time) || [],
        datasets: [
            {
                label: '습도',
                fill: true,
                backgroundColor,

                borderWidth: 2,
                borderDash: [],
                borderColor: '#1f8ef1',
                borderDashOffset: 0.0,

                pointBorderWidth: 20,
                pointBorderColor: "rgba(255,255,255,0)",

                pointRadius: 5,
                pointBackgroundColor: '#1f8ef1',
                
                pointHoverRadius: 5,
                pointHoverBorderWidth: 18,
                pointHoverBackgroundColor: "white",
                data: data?.map(v => Math.floor(v.humidity * 10) / 10) || [],
            }
        ],
    };
}

export const ChartBase = ({title, lineData, buttonList, index, setIndex}) => {
    return (
        <Card className="card-chart">
            <CardHeader>
                <Row>
                    <Col className="text-left" sm="6">
                        {title}
                    </Col>
                    <Col sm="6">
                        <ButtonGroup
                            className="btn-group-toggle float-right"
                            data-toggle="buttons"
                        >
                            {buttonList?.map((value, i) => <Button
                                key={i}
                                color="info"
                                className={classNames("btn-simple", {active: index === i})}
                                id={i + ''}
                                size="sm"
                                tag="label"
                                onClick={() => setIndex(i)}
                            >
                                {value}
                            </Button>)}
                        </ButtonGroup>
                    </Col>
                </Row>
            </CardHeader>
            <CardBody>
                <div className="chart-area">
                    <Line data={lineData} options={chartOption}/>
                </div>
            </CardBody>
        </Card>
    );
}