import {useState} from 'react';
import {FormGroup, Label, Input, Button} from 'reactstrap';
import {ModalBase} from './ModalBase';

export const AddWakeOnLanModal = props => {
    const [pcName, setPcName] = useState();
    const [macAddress, setMacAddress] = useState();

    const visibility = props.visibility;
    const setVisibility = props.setVisibility;
    return (
        <ModalBase title="WOL 추가" visibility={visibility} setVisibility={setVisibility}>
            <FormGroup>
                <Label for="pc">PC 이름</Label>
                <Input
                    id="pc"
                    placeholder="PC 이름"
                    style={{color: 'black'}}
                    value={pcName}
                    onChange={(e) => setPcName(e.target.value)}/>
            </FormGroup>
            <FormGroup>
                <Label for="mac">MAC 주소</Label>
                <Input
                    id="mac"
                    placeholder="MAC 주소"
                    style={{color: 'black'}}
                    value={macAddress}
                    onChange={(e) => setMacAddress(e.target.value)}/>
            </FormGroup>
            <Button color='info' className='animation-on-hover' onClick={() => props.onSubmit(pcName, macAddress)}>추가</Button>
            <Button className='animation-on-hover' onClick={() => setVisibility(false)}>취소</Button>
        </ModalBase>
    );
};