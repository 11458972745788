// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-theme{
    height: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-title{
    text-align: center;
    color: #fff;
    font-weight: bold;
    font-size: 25px;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/login.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,SAAS;IACT,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,iBAAiB;IACjB,eAAe;AACnB","sourcesContent":[".login-theme{\r\n    height: 100%;\r\n    margin: 0;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n}\r\n\r\n.login-title{\r\n    text-align: center;\r\n    color: #fff;\r\n    font-weight: bold;\r\n    font-size: 25px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
