import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col,
} from "reactstrap";
import {useRef, useEffect} from "react";
import {CheckerHistory} from '../components/device/CheckerHistory';
import {TemperatureChart} from "../components/chart/TemperatureChart";

import {useAuth} from "../components/provider/AuthProvider";
import {useData} from "../components/provider/DataProvider";
import {SwitchBotHistory} from "../components/device/SwitchBotHistory";
import {HumidityChart} from "components/chart/HumidityChart";

export const Statistics = () => {
    const {jwtFetch} = useAuth();
    const {state, dispatch, notifyError} = useData();

    useEffect(() => { // Webpage 기본 데이터 로드
        if(!state.checkerHistory){
            jwtFetch(`/data/checker?history=true`, {method: 'POST'})
            .then(async res => dispatch({key: 'checkerHistory', value: await res.json()}))
            .catch(notifyError);
        }
        if(!state.switchBotHistory){
            jwtFetch(`/data/switch_bot?history=true`, {method: 'POST'})
            .then(async res => dispatch({key: 'switchBotHistory', value: await res.json()}))
            .catch(notifyError);
        }
    }, []);

    return (
        <div className="content">
            <Row>
                <Col xs="12">
                    <TemperatureChart temperature={state.temperature} history={state.sensorHistory || []}/>
                </Col>
                <Col xs="12">
                    <HumidityChart humidity={state.humidity} history={state.sensorHistory || []}/>
                </Col>
                <Col md="6">
                    <CheckerHistory checkerList={state.checkerHistory || []}/>
                </Col>
                <Col md="6">
                    <SwitchBotHistory switchBotList={state.switchBotHistory || []}/>
                </Col>
            </Row>
        </div>
    );
}
