import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table
} from "reactstrap";
import '../../assets/css/checker.css';
import {dateToString} from '../../utils/utils';

const Checker = props => {
    const device = props.device;
    let battery = device.battery;
    if(!battery){
        battery = '전원';
    }else{
        battery += '%';
    }
    return (
        <tr>
            <td className="text-center">{device.number}</td>
            <td className="text-center">{device.name}</td>
            <td className="text-center">{device.open ? '열림' : '닫힘'}</td>
            <td className="text-center">{battery}</td>
            <td className="text-center">{dateToString(device.date, true)}</td>
        </tr>
    );
}

export const CheckerHistoryTable = ({list}) => {
    return (
        <Table className="tablesorter" responsive>
            <thead className="text-primary">
                <tr>
                    <th className="text-center">No</th>
                    <th className="text-center">장소</th>
                    <th className="text-center">상태</th>
                    <th className="text-center">배터리</th>
                    <th className="text-center">시각</th>
                </tr>
            </thead>
            <tbody>
                {list.map((device, index) => <Checker key={index} device={device}/>)}
            </tbody>
        </Table>
    );
}

export const CheckerHistory = ({checkerList}) => {
    return (
        <Card>
            <CardHeader>
                <CardTitle tag="h2">체커 기록</CardTitle>
            </CardHeader>
            <CardBody>
                {/* <div className="table-full-width table-responsive"> */}
                    <CheckerHistoryTable list={checkerList}/>
                {/* </div> */}
            </CardBody>
        </Card>
    );
};