import {Modal, ModalBody} from 'reactstrap';

export const ModalBase = ({visibility, setVisibility, title, children}) => {
    return (
        <Modal isOpen={visibility} toggle={() => setVisibility(!visibility)}>
            <div className="modal-header">
                <h2 className="modal-title">{title}</h2>
            </div>
            <ModalBody>
                {children}
            </ModalBody>
        </Modal>
    );
}