import React, {useRef, useEffect} from "react";
import {NavLink, useLocation} from "react-router-dom";
//import {PropTypes} from "prop-types";

import PerfectScrollbar from "perfect-scrollbar";

import {Nav, NavLink as ReactstrapNavLink} from "reactstrap";

let ps;

export const Sidebar = (props) => {
    const location = useLocation();
    const sidebarRef = useRef(null);
    const activeRoute = (routeName) => {
        return location.pathname === routeName ? "active" : "";
    };

    useEffect(() => {
        if(navigator.platform.indexOf("Win") > -1){
            ps = new PerfectScrollbar(sidebarRef.current, {
                suppressScrollX: true,
                suppressScrollY: false,
            });
        }
        return () => {
            if(navigator.platform.indexOf("Win") > -1){
                ps.destroy();
            }
        };
    });

    return (
        <div className="sidebar" data='blue'>
            <div className="sidebar-wrapper" ref={sidebarRef}>
                <Nav>
                    {props.routes.map((prop, key) => {
                        if(prop.redirect) return null;
                        return (
                            <li
                                key={key}
                                className={activeRoute(prop.path)}>
                                <NavLink
                                    to={prop.path}
                                    className="nav-link"
                                    onClick={props.toggleSidebar}>
                                    <i className={prop.icon}/>
                                    <p>{prop.sidebar}</p>
                                </NavLink>
                            </li>
                        );
                    })}
                </Nav>
            </div>
        </div>
    );
}
// Sidebar.propTypes = {
//     routes: PropTypes.arrayOf(PropTypes.object),
// };
