import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Row,
    Col
} from "reactstrap";
import {useEffect} from "react";
import '../../assets/css/switch-bot.css';
import SwitchIcon from "../../assets/icon/switch.png";
import {useAuth} from "../../components/provider/AuthProvider";
import {useData} from "../../components/provider/DataProvider";

const SwitchBot = (props) => {
    const device = props.data;
    const onClick = (event) => {
        const channel = +event.target.name;
        const body = JSON.stringify({
            id: device.id,
            channel,
            state: !device.switch[channel]
        });
        fetch('/api/switch', {
            method: 'POST',
            body,
            headers: {"Content-Type": "application/json"},
        });
    };
    return (
        <div style={{padding: '4px 1px'}}>
            <Row className="switch-bot" style={{margin: 0}}>
                <Col xs='2' style={{display: 'flex', padding: 0, justifyContent: 'center'}}>
                    <img src={SwitchIcon} className="toggle-icon" alt="Toggle Icon"/>
                </Col>
                <Col xs='4' style={{padding: 0}}>
                    <span className='switch-bot-name'>
                        <span style={{color: 'red'}}>{device.connected ? '' : '!'}</span>
                        {device.name}
                    </span>
                </Col>
                <Col xs='6' style={{padding: 0, alignItems: 'center'}}>
                    <div className="switch-bot-button-parent">
                        <span>{device.switchName[0]}</span>
                        <Button
                            name="0"
                            color={device.switch[0] ? "info" : ""}
                            className="animation-on-hover"
                            onClick={onClick}
                            style={{transition: '0.3s'}}
                        >
                            {device.switch[0] ? "끄기" : "켜기"}
                        </Button>
                    </div>
                    <div className="switch-bot-button-parent">
                        <span>{device.switchName[1]}</span>
                        <Button
                            name="1"
                            color={device.switch[1] ? "info" : ""}
                            className="animation-on-hover"
                            onClick={onClick}
                        >
                            {device.switch[1] ? "끄기" : "켜기"}
                        </Button>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export const SwitchBotList = () => {
    const {jwtFetch} = useAuth();
    const {state, dispatch} = useData();
    
    useEffect(() => {
        if(!state.switchBotList){
            jwtFetch(`${window.location.origin}/data/switch_bot`, {method: 'POST'})
            .then(async res => dispatch({key: 'switchBotList', value: await res.json()}))
            .catch(error => console.error(error));
        }
    }, []);
    return (
        <Card>
            <CardHeader>
                <CardTitle tag="h2">스위치 봇 목록</CardTitle>
            </CardHeader>
            <CardBody>
                {state.switchBotList?.map((sw, i) => <SwitchBot key={i} data={sw}/>)}
            </CardBody>
        </Card>
    );
}