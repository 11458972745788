import "../assets/css/login.css";

import {useEffect, useState} from "react";
import {Card, CardBody, Form, FormGroup, Label, Input, Button} from "reactstrap";
import {useAuth} from "../components/provider/AuthProvider";
import { useData } from "../components/provider/DataProvider";

export const Login = () => {
    const {notiRef} = useData();
    const [load, setLoad] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [tryLogin, setTryLogin] = useState(false);
    const {authentication, setAuthentication} = useAuth();

    const login = async event => {
        event.preventDefault();
        if(authentication || tryLogin){
            return;
        }
        try{
            setTryLogin(true);
            const res = await fetch(`${window.location.origin}/login/account`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({username, password}),
                credentials: 'include',
            });
            const jsonData = await res.json();
            if(res.ok){
                setAuthentication(true);
            }else{
                notiRef.current.notificationAlert({
                    place: 'tr',
                    type: 'danger',
                    icon: "tim-icons icon-bell-55",
                    message: <div>{jsonData.error}</div>,
                    autoDismiss: 3,
                });
            }
        }catch(error){
            notiRef.current.notificationAlert({
                place: 'tr',
                type: 'danger',
                icon: "tim-icons icon-bell-55",
                message: <div>{error}</div>,
                autoDismiss: 3,
            });
            console.error('Login failed:', error);
        }
        setTryLogin(false);
    };

    useEffect(() => {
        document.body.classList.add("login-theme");
        document.documentElement.classList.add("login-theme");
        setLoad(true);
        return () => {
            document.body.classList.remove("login-theme");
            document.documentElement.classList.remove("login-theme");
        };
    }, []);

    if(!load){
        return <></>;
    }

    return (
        <div className="container">
            <Card>
                <CardBody>
                    <div className="login-title mb-1">Iot Management</div>
                    <Form onSubmit={login}>
                        <FormGroup className="mb-3">
                            <Label for="username">사용자명</Label>
                            <Input
                                required
                                name="username"
                                type="text"
                                id="username"
                                placeholder="사용자명"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}/>
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <Label for="password">비밀번호</Label>
                            <Input
                                required
                                type="password"
                                id="password"
                                name="password"
                                placeholder="비밀번호"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}/>
                        </FormGroup>
                        <Button type="submit" color="info" style={{width: '100%'}}>로그인</Button>
                    </Form>
                </CardBody>
            </Card>
        </div>
    );
}